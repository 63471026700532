import { InvoicePdfModel } from '@/models/pdf-model'
import PdfHelper from './PdfHelpers'

export default class InvoiceHelpers {
  public static GeneratePDFModel (fileName: string, docPreviewElement: any) {
    const model: InvoicePdfModel = {
      ...PdfHelper.GeneratePDFModel(docPreviewElement, 'doc-preview-a4 doc-facture', 20, 45, 0, 0, 60, 20, 200, 100),
      targetFileName: fileName
    }
    return model
  }

  public static formatNumberWithThousandSeparator (value: number, separator = "'") {
    if (!value) {
      return '0.00'
    }
    // Ensure the number has two decimal places
    const formattedValue = Number(value).toFixed(2)
    // Add thousand separators
    return formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  }
}
