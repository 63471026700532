import { InvoiceModel, InvoiceItemModel } from '@/models/invoice-models'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Commons from '../../Helpers/commons'
import { invoiceSexes, invoiceFormulePolitesse } from '@/views/Patients/Dossier/Constants'
import { InvoiceDocumentType } from '@/views/Facturation/Constants'

@Component({
  components: {
  }
})
export default class FactureBase extends Vue {
  @Prop({ default: InvoiceDocumentType.Invoice })
  public documentType!: InvoiceDocumentType

  @Prop()
  public invoice!: InvoiceModel

  public get hasQrCode () {
    switch (this.documentType) {
      case InvoiceDocumentType.NoteCredit:
        return false
      case InvoiceDocumentType.InvoiceReminder:
      default:
        return true
    }
  }

  public get invoiceTitle () {
    switch (this.documentType) {
      case InvoiceDocumentType.InvoiceReminder:
        return 'Rappel de facture'
      case InvoiceDocumentType.NoteCredit:
        return 'Note de crédit'
      default:
        return 'Facture'
    }
  }

  public get overrideSign () {
    if (this.documentType === InvoiceDocumentType.NoteCredit) {
      return '- '
    }
    return ''
  }

  public getItemPrice (price?: number) {
    if (this.documentType === InvoiceDocumentType.NoteCredit) {
      return price ? -price : price
    }
    return price
  }

  public get invoiceDate () {
    switch (this.documentType) {
      case InvoiceDocumentType.InvoiceReminder:
        return Commons.TransformDateFormat(this.invoice?.dateReminder)
      case InvoiceDocumentType.NoteCredit:
        return Commons.TransformDateFormat(this.invoice?.noteCredit?.date)
      default:
        return Commons.TransformDateFormat(this.invoice?.date)
    }
  }

  public get invoiceNote () {
    switch (this.documentType) {
      case InvoiceDocumentType.InvoiceReminder:
        return `${this.formulePolitesse},<br /><br />
        Sauf erreur ou omission de notre part, nous constatons que notre facture mentionnée ci-dessus est restée impayée.<br />
        <br />
        Étant persuadés qu'il s'agit d'un oubli de votre part, nous vous invitons à bien vouloir procéder au paiement dans les 20 jours. Dans le cas où le règlement aurait été adressé entre temps, nous vous prions de ne pas tenir compte de la présente.<br />
        <br />
        
        En vous remerciant par avance, nous vous prions d'agréer, Madame, Monsieur, l'expression de nos salutations distinguées.
      `
      case InvoiceDocumentType.NoteCredit:
        return 'Avec nos remerciements et nos meilleures salutations'
      default:
        return `Payable dans les <strong>30 jours</strong> net sans déduction : réclamations admises dans les 30 jours<br />
        Avec nos remerciements et nos meilleures salutations`
    }
  }

  public get displayPartPatientInfo () {
    return this.invoice.invoiceItems.some(x => x.isPatientPart)
  }

  public get destinataire () {
    if (this.invoice.isTP) {
      if (!this.invoice.insuranceId) {
        return `<strong>L'assurance pour ce patient n'est pas définie!</strong>`
      }
      return `
        ${Commons.ValueOrEmpty(this.invoice.insuranceName)}<br />
        ${Commons.ValueOrEmpty(this.invoice.insuranceAddress)}<br />
        CH-${Commons.ValueOrEmpty(this.invoice.insuranceNPA)} ${Commons.ValueOrEmpty(this.invoice.insuranceCity)}
      `
    }

    if (Commons.ValueOrEmpty(this.invoice.patientAlternateName)) {
      return `
      ${Commons.ValueOrEmpty(this.invoice.patientAlternateName)}<br />
      ${Commons.ValueOrEmpty(this.invoice.patientAlternateAddress)}<br />
      CH-${Commons.ValueOrEmpty(this.invoice.patientAlternateNpa)} ${Commons.ValueOrEmpty(this.invoice.patientAlternateCity)}
    `
    }

    return `
      ${Commons.ValueOrEmpty(this.invoice.patientFirstName)} ${Commons.ValueOrEmpty(this.invoice.patientLastName)}<br />
      ${Commons.ValueOrEmpty(this.invoice.patientAddress)}<br />
      CH-${Commons.ValueOrEmpty(this.invoice.patientNpa)} ${Commons.ValueOrEmpty(this.invoice.patientCity)}
    `
  }

  public get doctorAddress () {
    let addressString
    if (!this.invoice.doctorAddress && !!this.invoice.doctorEmployeur) {
      addressString = `
        <tr>
          <td>${Commons.ValueOrEmpty(this.invoice.doctorEmployeur)}</td>
        </tr>
        <tr>
          <td>c/o ${Commons.ValueOrEmpty(this.invoice.doctorFirstName)} ${Commons.ValueOrEmpty(this.invoice.doctorLastName)}</td>
        </tr>
      `
    } else {
      addressString = `
        <tr>
          <td>${Commons.ValueOrEmpty(this.invoice.doctorFirstName)} ${Commons.ValueOrEmpty(this.invoice.doctorLastName)}</td>
        </tr>
        <tr>
          <td>${Commons.ValueOrEmpty(this.invoice.doctorAddress)}</td>
        </tr>
      `
    }
    addressString += `
      <tr>
        <td>${Commons.ValueOrEmpty(this.invoice.doctorNpa)} ${Commons.ValueOrEmpty(this.invoice.doctorCity)}</td>
      </tr>
    `
    return addressString
  }

  public getLabel (item: InvoiceItemModel) {
    const commonPart = `${Commons.ValueOrEmpty(item.label)}${item.isPatientPart ? ' *' : ''}`
    if (item.remark) {
      return `${commonPart}<br /><i class="doc-x-light">${item.remark}</i>`
    }
    return commonPart
  }

  public get sexe () {
    return invoiceSexes.find(s => this.invoice.patientGender === s.value)?.text
  }

  public get formulePolitesse () {
    const v = this.invoice.isTP ? 3 : this.invoice.patientGender
    return invoiceFormulePolitesse.find(s => v === s.value)?.text
  }

  public roundPoints (item: InvoiceItemModel) {
    // if it's materiel prescription or materiel order, round it to nearest 2 digits
    if (item.type === 6 || item.type === 7) {
      return item.grossPoints.toFixed(2)
    }
    return item.grossPoints
  }
}
